import React, { useContext, useEffect, useState } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import './TrainingWizardStepModal.scss';
import Image from '../../base-components/BaseImage';
import HeaderModal from '../modal-components/HeaderModal';
import BaseButton from '../../base-components/BaseButton';
import imagePic from '../../../assets/icons/image.svg';
import { LikeStatus } from "../../../enums/LikeStatus";
import { IUserInterest, IUserOccasion, IProduct, IProductImage } from '../../../types/types-internal'
import { Brand } from "../../../enums/Brand";
import { ProductCategory } from "../../../enums/ProductCategory";

const TrainingWizardStepModal = (props) => {

    const [anchor, setAnchor] = useState(0);
    const [currentProducts, setCurrentProducts] = useState<IProduct[]>
        ([{ brand: Brand.NIKE, productCategory: ProductCategory.Lifestyle, productImages: [{ imageUrl: "" }] }])

    useEffect(() => {
        if (props.products?.length >= 1) {
            setCurrentProducts(props.products?.slice(0, 2));
        }
    }, [props.products])

    function advanceModal(likeStatus, ...products) {
        
        products.forEach(x => props.addProduct(x, likeStatus));
        let count = anchor + 2;
        if (count == props?.products?.length) {
            props.loadProducts();
            props.closeModal();
        }

        setCurrentProducts(props.products?.slice(count, count + 2));
        setAnchor(count);
    }

    return (
        <section className="training-wizard-modal mb-2">
            <HeaderModal
                span=""
                heading={`Now select some products that you'd buy for ${props.connectionName ? props.connectionName : `yourself`}`}
                paragraph="This helps WisePrezzie suggest gifts that you may like to your friends and family!"
            />

            <Container className="variants-container">
                <Row>
                    {currentProducts && currentProducts.length > 1
                        ? currentProducts.map(product => (
                            <Col sm={12} lg={6} className="variant-container">
                                <div className="variant-wrapper">
                                    <Image
                                        img={'https://' + product?.productImages[0]?.imageUrl}
                                        alt="" />
                                    <div className="variant-context">
                                        <div>
                                            <h2>{product?.title}</h2>
                                            <span>{product?.subtitle}</span>
                                        </div>
                                        <BaseButton
                                            title="Choose This"
                                            icon=""
                                            alt=""
                                            onClick={() => advanceModal(LikeStatus.Liked, product)}
                                        />
                                    </div>

                                </div>
                            </Col>
                        ))
                        :
                        <Row className="product-placeholder-container">
                            <Col sm={12} lg={6} className="wrapper">
                                <div className="product-placeholder-wrapper">
                                    <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
                                </div>
                            </Col>
                            <Col sm={12} lg={6} className="wrapper">
                                <div className="product-placeholder-wrapper">
                                    <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
                                </div>
                            </Col>
                        </Row>
                    }
                </Row>
            </Container>
            <Container className="button-wrapper">
                <Row>
                    <Col md={6}>
                        <BaseButton
                            title="Neither"
                            icon=""
                            alt=""
                            onClick={() => advanceModal(LikeStatus.Disliked, ...currentProducts)}
                        /></Col>
                    <Col md={6}>
                        <BaseButton
                            title="Both"
                            icon=""
                            alt=""
                            onClick={() => advanceModal(LikeStatus.Liked, ...currentProducts)}
                        /></Col>
                </Row>
            </Container>
        </section>
    )
}

export default TrainingWizardStepModal;
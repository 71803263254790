import React, { FunctionComponent, useState } from "react";
import { Modal, Button, Container, Row, Col } from 'react-bootstrap';
import './FeedbackPopUp.scss';
import BaseButton from '../../base-components/BaseButton';
import FeedbackModal from './FeedbackModal'

type FeedbackModalProps = {
    show: boolean,
    onHide: any,
}

function FeedbackPopUp(props: FeedbackModalProps) {
    let emptyFeedback ={ feedbackStatus: '', text: '' };
    const [feedback, setFeedback] = useState(emptyFeedback);
    const [isError, setIsError] = useState(false);

    const axios = require('axios').default;

    const sendFeeback = async () => {
        if (feedback.feedbackStatus || feedback.text) {
            setIsError(false)
            try{
                let response = await axios.post('api/Users/AddUserFeedback', feedback);
            }
            catch(error){
            }
            onHide();
        }
        else {
            setIsError(true);
        }
    }

    const onHide = () => {
        props.onHide();
        setIsError(false);
        setFeedback(emptyFeedback);
    }

    return (
        <Modal
            {...props}
            className="feedback-pop-up"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <FeedbackModal onFeedbackChange={setFeedback} />
            </Modal.Body>
            <Modal.Footer className="feedback-buttons">
                <Container>
                    <Row className="buttons-container">
                        <Col lg={6}>
                            <BaseButton
                                title="Send"
                                onClick={sendFeeback}
                            />
                            {isError ?
                                <span>No data to submit</span>
                                : null}
                        </Col>
                        <Col lg={6} className="cancel">
                            <BaseButton
                                onClick={onHide}
                                title="Cancel"
                            />
                        </Col>
                    </Row>
                </Container>
            </Modal.Footer>
        </Modal>
    );
}

export default FeedbackPopUp;
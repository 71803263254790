import React, { FunctionComponent } from "react";
import './HeaderModal.scss';

type HeaderProps = {
    span: string,
    heading: string,
    paragraph: string,
}

const HeaderModal: FunctionComponent<HeaderProps> = ({ heading, span, paragraph }) => {
    return (
        <div style={{width: 'max-content', margin: 'auto', position: 'relative', zIndex: 1, background: 'white'}}>
            <section className="header-container">
                <h1><span>{span}</span>{heading}</h1>
                <p>{paragraph}</p>
            </section>
        </div>
    )
}

export default HeaderModal;
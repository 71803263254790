import './ProductRecommendationsPage.scss';
import React, {useEffect, useState} from 'react'
import AdminNavbar from "../../components/navbar/AdminNavbar"
import {Container} from 'react-bootstrap'
import UserProfileContainer from '../../components/profile-components/UserProfileContainer'
import OccasionComponent from '../../components/occasion-components/OccasionComponent';
import {IProduct, IUserOccasion} from '../../types/types-internal';
import LinearProgress from '@material-ui/core/LinearProgress';
import {Fade} from '@material-ui/core';
import {useHistory} from "react-router-dom";
import {Gender} from '../../enums/Gender'
import {useRecoilState} from 'recoil';
import {selectedConnection, userState} from '../../state/atoms';
import OccasionService from '../../services/OccasionServices';
import FeedbackPopUp from '../../components/modal/modal-feedback/FeedbackPopUp';
import RecentRecommendations from '../../components/product-components/RecentRecommendations';
import LoadingOverlay from 'react-loading-overlay';
import {ProductCategory} from '../../enums/ProductCategory';
import {Brand} from '../../enums/Brand';
import {IsNullOrEmpty} from '../../utils/Utils';
import {LikeStatus} from '../../enums/LikeStatus';
import GoogleFbLoginMissingDataPopUp
    from '../../components/modal/modal-google-fb-login-missing-data/GoogleFbLoginMissingDataPopUp';
import {STATUS} from 'react-joyride';

const ProductRecommendationsPage = () => {

    const [connection, setConnection] = useRecoilState(selectedConnection);
    const [user, setUser] = useRecoilState(userState)

    const [showProgressBar, setShowProgressBar] = useState(true)
    const history = useHistory()
    const [occasions, setOccasions] = useState<IUserOccasion[]>([])
    const [selectedOccasion, setSelectedOccasion] = useState<IUserOccasion>()
    const [showFeedback, setShowFeedback] = useState(false);
    const [editedOccasions, setEditedOccasions] = useState(false)
    const [loadingOverlayActive, setLoadingOverlayActive] = useState(false)
    const [products, setProducts] = useState<IProduct[]>
        ([{ brand: Brand.NIKE, productCategory: ProductCategory.Lifestyle, productImages: [{ imageUrl: "" }] }])
    const [anchor, setAnchor] = useState(0)
    const [showFillGenderAndBirthDatePopUp, setShowFillGenderAndBirthDatePopUp] = useState(false);
    const [showTrainingModal, setShowTrainingModal] = useState(false);
    const [runTour, setRunTour] = useState(true)

    const handleJoyrideCallback = data => {
        const { action, index, status, type } = data;
        if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
            setRunTour(false);
            localStorage.setItem("tourCompleted", "1")
        }
    };

    //figure what to do the steps
    const steps = [
        {
            target: '.tour-first-step .image-wrapper',
            disableBeacon: true,
            title: "Profile page",
            content: `This is the profile page for ${user.userName}. Basic info you've entered is here. To change it, select the profile icon.`,
        },
        {
            target: '.recommended-products-container',
            disableBeacon: true,
            title: "Gift Recommendations",
            content: `These are the first recommendations! They are based on basic info you've shared (${user.userName} age, event, and budget). Share more about your / ${user.userName} interests (below), for WisePrezzie to refine gift recommendations.`,
        },
        {
            target: '.products-container .button',
            disableBeacon: true,
            title: "New Recommendations",
            content: 'If you don’t like any of the recommendations, select New recommendations.',
        },
        {
            target: '.recommended-products-container .option-buttons-group',
            disableBeacon: true,
            title: "Love it! / No thanks",
            content: `Select Love it! to save the item to Favorites or a Gift Registry. Select No thanks to remove the item from recommendations. Either way, we’ll use your response to improve future recommendations!`,
        },
        {
            target: '.recipients-preferences-container ',
            disableBeacon: true,
            title: "Preferences",
            content: `Here are categories of preferences that typically are useful for refining gift recommendations. Share as many or as few as you'd like. [(if profile is the gift recipient) You can even email this profile to ${user.userName} to have them fill it out.]`,
        },
    ]

    useEffect(() => {
        var url = new URL(window.location.toString())
        let connectionId = Number(url.searchParams.get("id"));
        if (connectionId) {
            (async () => {
                await getSelectedConnection(connectionId)
                await setPageOccasions(connectionId);
            })();

        } else {
            (async () => {
                setConnection({ ...connection, connectionId: 0 });
                await setPageOccasions(0);
            })();
        }
        setShowProgressBar(false);
    }, [connection.connectionId])

    useEffect(() => {
        refreshProducts(selectedOccasion);
    }, [connection.connectionId]);

    async function refreshProducts(occasion) {
        setLoadingOverlayActive(true)
        await getRecommendedProducts(occasion);
        setLoadingOverlayActive(false);
    }

    async function getRecommendedProducts(occasion) {
        try {
            var response;
            let connectionId = 0;
            if (connection?.connectionId) {
                connectionId = connection.connectionId;
            }
            if (!IsNullOrEmpty(occasion) && occasion.budget) {
                let budget = occasion.budget
                response = await fetch(`api/Products/GetRecommendedProducts?connectionId=${connectionId}&budget=${budget}`);
            }
            else {
                response = await fetch(`api/Products/GetRecommendedProducts?connectionId=${connectionId}`);
            }

            if (response.status == 204) {
                setShowTrainingModal(true);
                return;
            }
            
            let recProducts: IProduct[] = await response.json();
            setProducts(recProducts);
            setAnchor(anchor + 9)
        } catch (error) {
            console.log("err", error)
        }
    }

    async function addProduct(product, likeStatus) {
        try {
            product["likeStatus"] = LikeStatus[likeStatus]

            const settings = {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ ...product, occasionId: selectedOccasion?.occasionId })
            };

            var updateProductResponse = await fetch(`api/Products/AddUserProduct?connectionId=${connection?.connectionId}`, settings)
            if (updateProductResponse.status == 400) {
                updateProductResponse = await fetch(`api/Products/UpdateUserProduct?connectionId=${connection?.connectionId}`, settings)
            }

        } catch (error) {
            console.log("err", error)
        }
        finally {
            return null;
        }
    }
    function hideProduct(product) {
        setProducts(products.filter(x => x.externalProductId != product.externalProductId))
    }

    const setPageOccasions = async (connectionId) => {
        let pulledOccasions = await OccasionService.GetOccasions(connectionId);
        if (connectionId > 0 && pulledOccasions && pulledOccasions.length > 0) {
            setOccasions(pulledOccasions as IUserOccasion[]);
            if (!selectedOccasion) {
                setSelectedOccasion(pulledOccasions[0]);
            }
        }
        else {
            setOccasions(pulledOccasions);
            setSelectedOccasion(pulledOccasions[0]);
        }
    }

    async function getSelectedConnection(connectionId) {
        let response = await fetch(`api/Users/GetSpecificUserConnection?connectionId=${connectionId}`)
        if (response.status == 400) {
            history.push("/404")
        }

        let data = await response.json();
        let imageResp = await ('api/Images/GetImage?url=' + data.imagePathUrl);
        if (imageResp.includes('null') || imageResp.includes('undefined')) {
            data.profilePhotoDownloadUrl = null;
        }
        else {
            data.profilePhotoDownloadUrl = imageResp;
        }
        if (!data.gender) {
            data.gender = Gender.NONBINARY
        }
        setConnection(data);
    }

    const updateUser = (updatedUser, updatedProfilePicture) => {
        (async () => {
            const settings = {
                method: 'PUT',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(updatedUser)
            };
            let respData = await fetch('api/Users/UpdateUser', settings);

            //If there is an image, update profile image
            if (updatedProfilePicture) {
                const imageSettings = {
                    method: 'POST',
                    headers: {
                        Accept: 'multipart/form-data'
                    },
                    body: updatedProfilePicture
                };

                let imageData = await fetch(`api/Images/ChangePlaceholderProfileImage?connectionId=${connection?.connectionId}`, imageSettings);
                let imageUrl = await imageData.text();
                updatedUser.imagePathUrl = imageUrl;

                let downloadableImageData = await fetch('api/Images/GetImage?url=' + imageUrl)
                updatedUser.profilePhotoDownloadUrl = downloadableImageData.url;
            }
            
            setConnection(updatedUser);
            setOccasions(prev => {
                const birthdays = prev.filter(p => p.typeOfOccasion.toString().toLowerCase() === 'birthday');
                for(let birthday of birthdays) {
                    birthday.ocassionDate = updatedUser.birthday;
                }
              
                return prev;
            });
            
            window.location.reload();
        })();
    }


    function onNewOccasions(e) {
        setOccasions([...occasions, e])
        setSelectedOccasion(e);
    }
    
    function onBudgetEdit() {
        window.location.reload();
    }

    function deleteOccasion(occasion: IUserOccasion) {
        (async () => {
            let connectionId = 0;
            if (connection.connectionId) {
                connectionId = connection.connectionId
            }
            const settings = {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    budget: occasion["budget"],
                    typeOfOccasion: occasion["typeOfOccasion"],
                    connectionId: connectionId,
                    occasionId: occasion["occasionId"],
                    ocassionDate: occasion["date"]
                })
            };
            let respData = await fetch('api/Occasions/RemoveOccasion', settings);
            setPageOccasions(connectionId);
        })();
        if (occasion.typeOfOccasion == selectedOccasion?.typeOfOccasion) {
            if (occasions.filter(x => x != occasion).length > 0) {
                setSelectedOccasion(occasions[0]);
            }
            else {
                setSelectedOccasion(undefined);
            }
        }
    }

    function editOccasionBudget(occasion, budget, date) {
        (async () => {
            if (budget < 100) {
                budget = 100
            }
            let connectionId = 0;
            if (connection.connectionId) {
                connectionId = connection.connectionId
            }
            const settings = {
                method: 'PUT',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    budget: budget,
                    typeOfOccasion: occasion.typeOfOccasion,
                    occasionId: occasion.occasionId,
                    connectionId: connectionId,
                    ocassionDate: new Date(date)
                })
            };
            
            await fetch('api/Occasions/UpdateOccasion', settings);
            setEditedOccasions(true)
            
            await setPageOccasions(connectionId);
            setSelectedOccasion({ ...occasion, ocassionDate: date, budget: budget });
            
            if(occasion.typeOfOccasion.toString().toLowerCase() === 'birthday') {
                setConnection((prev) => ({...prev, birthday: date}));
            }
        })();
    }

    return (
        <>
            <div hidden={!showProgressBar}>
                <LinearProgress />
            </div>

            {!showProgressBar &&
                <>
                    {/* <Joyride
                        callback={handleJoyrideCallback}
                        showSkipButton={true}
                        hideBackButton={true}
                        run={runTour}
                        continuous={true}
                        steps={steps}
                        scrollOffset={300}
                        styles={{
                            options: {
                                zIndex: 10000,
                                width: "500px"
                            },
                            tooltipContainer: {
                                textAlign: 'left',

                            },
                            buttonNext: {
                                backgroundColor: "#3c50e0",
                                borderRadius: "30px",
                                padding: "19px",
                                width: "100px",
                                textTransform: "uppercase"
                            }
                        }}
                    /> */}

                    <AdminNavbar />
                    <article className="new-gifts-page">
                        <Fade in={true} timeout={500}>
                            <Container fluid>
                                <UserProfileContainer
                                    connection={connection && connection.connectionId! > 0 ? connection : user}
                                    updateConnection={updateUser}
                                    occasions={occasions}
                                    selectedOccasion={{ occasion: selectedOccasion, setOccasion: setSelectedOccasion }}
                                    editedOccasions={editedOccasions}
                                />
                                <LoadingOverlay
                                    active={loadingOverlayActive}
                                    spinner
                                    text='Calculating new recommendations...'
                                >
                                </LoadingOverlay>
                                <GoogleFbLoginMissingDataPopUp icon="" title="" show={showFillGenderAndBirthDatePopUp} connectionId={connection.connectionId} onReady={() => setShowTrainingModal(true)} />
                                <FeedbackPopUp show={showFeedback} onHide={() => setShowFeedback(false)} />
                                <RecentRecommendations
                                    products={products}
                                    occasion={selectedOccasion}
                                    refreshProducts={(occasion) => refreshProducts(occasion)}
                                    addProductToFavorites={(product, likeStatus) => addProduct(product, likeStatus)}
                                    hideProduct={(product) => hideProduct(product)}
                                    user={connection}
                                    loadProducts={() => refreshProducts(selectedOccasion)}
                                    setShowTrainingModal={setShowTrainingModal}
                                    showTrainingModal={showTrainingModal}
                                    connectionName={connection.firstName ? connection.firstName : connection.lastName}
                                    showFeedbackModal={setShowFeedback}
                                />
                            </Container>
                        </Fade>
                        <OccasionComponent
                            occasions={occasions}
                            setOccasions={setOccasions}
                            connection={connection}
                            onBudgetEdit={onBudgetEdit}
                            onEditedOccasions={(e) => onNewOccasions(e)}
                            onDeleteOccasion={(occ) => deleteOccasion(occ)}
                            onEditOccasionBudget={(occ, budget, date) => editOccasionBudget(occ, budget, date)}
                        />
                    </article>
                </>}
        </>
    )

}

export default ProductRecommendationsPage;
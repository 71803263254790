import React from "react";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";

interface CustomGoogleProps {
    onSuccess: (response: any) => void;
}

const CustomGoogle: React.FC<CustomGoogleProps> = ({ onSuccess }) => {
    const login = useGoogleLogin({
        onSuccess: async (tokenResponse) => {
            const userInfo = await axios.get("https://www.googleapis.com/oauth2/v3/userinfo", {
                headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
            });

            onSuccess(userInfo.data);
        },
        onError: () => {
            console.log("Login Failed");
        },
    });

    return (
        <button onClick={() => login()}
            className="google-button">
            <span>Login with Google</span>
        </button>
    );
};

export default CustomGoogle;
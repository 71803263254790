import React, { FunctionComponent, useEffect, useState } from "react";
import { IUser, IUserOccasion } from '../../types/types-internal';
import { formatPrice } from '../../utils/CurrencyUtils';
import { publicHolidaysKvP } from "../../utils/Utils";
import { getOccasionDate } from './functions/getOccasionDate';

type EventInfoProps = {
    occasion?: IUserOccasion,
}

const EventInfo: FunctionComponent<EventInfoProps> = ({ occasion }) => {
    function getBudget() {
        if (typeof (occasion) === "object") {
            return "Budget: $" + occasion.budget
        }
    }

    function getOccasionType() {
        if (typeof (occasion) === "object") {
            var occasionName = publicHolidaysKvP.find(hol => hol.key == occasion.typeOfOccasion)
            return occasionName?.value;
        }
    }

    return (
        <section className="event-info">
            <span>Event</span>
            <h3>{getOccasionType()}</h3>
            {getOccasionDate(occasion)}
            <p>{getBudget()}</p>
        </section>
    )
}

export default EventInfo;
import React, { FunctionComponent, useState, useEffect, useContext } from "react";
import { Modal, Button } from 'react-bootstrap';
import BaseButton from '../../base-components/BaseButton';
import TrainingWizardStepModal from './TrainingWizardStepModal'
import {  IProduct } from '../../../types/types-internal'
import { LikeStatus } from "../../../enums/LikeStatus";
import { Gender } from '../../../enums/Gender';

interface Props {
    show: boolean,
    onHide: any,
    connectionId?: number,
    loadProducts: any,
    connectionName?: string,
    occasion?: any,
    gender?: string
}


function TrainingWizardStepPopUp(props: Props) {
    const [products, setProducts] = useState<IProduct[]>();

    useEffect(() => {
        if (props.show) {
            (async () => {
                await getTrainingProducts();
            })();
        }
    }, [props.show]);

    async function getTrainingProducts() {
        try {
            let budget = props?.occasion?.budget ? props.occasion.budget : 0;
            let gender = props?.gender ? props.gender : Gender.NONBINARY;
            const settings = {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ gender: gender, budget: budget })
            };
            const trainingProducts = await fetch(`api/Products/GenerateTrainingStepProducts`, settings);

            let products: IProduct[] = await trainingProducts.json()
            setProducts(products);
        } catch (error) {
            console.log("err", error)
        }
        finally {
            return null;
        }
    }

    async function addProduct(product, likeStatus) {
        try {
            product["likeStatus"] = LikeStatus[likeStatus]
            const settings = {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(product)
            };
            if (props.connectionId) {
                settings.body = JSON.stringify(product)
                var updateProductResponse = await fetch(`api/Products/AddUserProduct?connectionId=${props.connectionId}`, settings)
                if (updateProductResponse.status == 400) {
                    updateProductResponse = await fetch(`api/Products/UpdateUserProduct?connectionId=${props.connectionId}`, settings)
                }
            } else {
                var updateProductResponse = await fetch(`api/Products/AddUserProduct`, settings)
                if (updateProductResponse.status == 400) {
                    updateProductResponse = await fetch(`api/Products/UpdateUserProduct`, settings)
                }
            }

        } catch (error) {
            console.log("err", error)
        }
        finally {
            return null;
        }
    }

    return (
        <Modal
            {...props}
            className="add-connection-pop-up"
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <TrainingWizardStepModal
                    products={products}
                    loadProducts={() => props.loadProducts()}
                    addProduct={addProduct}
                    closeModal={props.onHide}
                    connectionName={props.connectionName}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}

type ButtonProps = {
    title: string,
    icon: string,
    loadProducts?: any
}


const TrainingWizardPopUpWithButton: FunctionComponent<ButtonProps> = (props) => {

    const [modalShow, setModalShow] = React.useState(false);

    function onClick() {
        setModalShow(true)
    }

    return (
        <>
            <BaseButton
                onClick={onClick}
                title={props.title}
                icon={props.icon}
                alt="">
            </BaseButton>
            <TrainingWizardStepPopUp
                show={modalShow}
                loadProducts={() => props.loadProducts()}
                onHide={() => setModalShow(false)}
            />
        </>
    );
}



export { TrainingWizardPopUpWithButton, TrainingWizardStepPopUp };